import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NftLandingPage from "./pages/nftlandingpage";
import NftSecurePage from "./pages/nftsecurepage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<NftSecurePage />} />
        </Route>
        <Route path="/landing">
          <Route path=":publicKey" element={<NftLandingPage />} />
          <Route index element={<NftLandingPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
